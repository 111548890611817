import Footer from "./Footer";
import NavBar from "./NavBar";
import "../static/css/base.css";
import "../static/css/import_booking.css";
import { AjaxHelper } from "../utils/AjaxHelper";
import { useAuth } from "../provider/authProvider";

/**
 * Import booking component for the web application.
 * @param {Object} props - The properties of the component.
 * @param {AjaxHelper} props.ajaxHelper - The AJAX helper object.
 * @returns {JSX.Element} The import booking component.
 */
export default function ImportAlphaTimingJson({ ajaxHelper }) {
    const { token } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const file = document.getElementById("event-file").files[0];
        const formData = new FormData();
        formData.append("file", file);

        try {
            // Send booking file to API
            const response = await ajaxHelper.post("/Admin/AlphaTiming/Import/s3", formData, token.token, "multipart/form-data");
            // Redirect to home page
            const importSuccesses = document.querySelector(".import-successes");
            importSuccesses.innerHTML = `<p>AlphaTiming event JSON file imported successfully.</p>`;
            const importErrors = document.querySelector(".import-errors");
            importErrors.innerHTML = "";
        } catch(err) {
            // Display generic error message
            const importSuccesses = document.querySelector(".import-successes");
            importSuccesses.innerHTML = "";
            const importErrors = document.querySelector(".import-errors");
            importErrors.innerHTML = err.response.data;
        }
    }

    return (
        <div className="import-booking-page">
            <NavBar />
            <main>
                <h1>Import Event JSON</h1>
                <form onSubmit={handleSubmit}>
                    <div className="field-container">
                    <p>
                        <label htmlFor="event-file">EVENT FILE</label>
                        <input id="event-file" type="file" accept=".json" required />
                    </p>
                    </div>
                    <div className="centre-field-container">
                    <p>
                        <input type="submit" value="IMPORT" />
                    </p>
                    </div>
                </form>
                <div className="import-errors"></div>
                <div className="import-successes"></div>
            </main>
            <div className="footer-padding"/>
            <Footer />
        </div>
    );
}