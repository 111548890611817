import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Welcome from "../components/Welcome";
import Login from "../components/Login";
import ImportBooking from "../components/ImportBooking";
import ChangePassword from "../components/ChangePassword";
import ImportBasedAudits from "../components/ImportBasedAudits";
import UnsuccessfulStatusAudits from "../components/UnsuccessfulStatusAudits";
import UserBasedAudits from "../components/UserBasedAudits";
import TableUnstyled from "../components/UserBasedAudits";
import ImportAlphaTimingJson from "../components/ImportTiming";

const Routes = ({ ajaxHelper }) => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/",
      element: <Welcome />,
    }
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute ajaxHelper={ajaxHelper} />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/change-password",
          element: <ChangePassword ajaxHelper={ajaxHelper} />,
        },
      ],
    },
  ];

  const routesForSuperAdminOnly = [
    {
      path: "/",
      element: <ProtectedRoute ajaxHelper={ajaxHelper} />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/audit/imports",
          element: <ImportBasedAudits ajaxHelper={ajaxHelper} />,
        },
        {
          path: "/audit/unsuccessful",
          element: <UnsuccessfulStatusAudits ajaxHelper={ajaxHelper} />,
        },
        {
          path: "/audit/credentials",
          element: <UserBasedAudits ajaxHelper={ajaxHelper} />,
        }
      ]
    }
  ];

  const routesForOperatorOnly = [
    {
      path: "/",
      element: <ProtectedRoute ajaxHelper={ajaxHelper} />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/import-booking",
          element: <ImportBooking ajaxHelper={ajaxHelper} />,
        },
        {
          path: "/import-event-json",
          element: <ImportAlphaTimingJson ajaxHelper={ajaxHelper} />,
        }
      ]
    }
  ]

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/login",
      element: <Login ajaxHelper={ajaxHelper} />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...(token && token.claims && Object.keys(token.claims).includes("SUPER_ADMIN") ? routesForSuperAdminOnly : []),
    ...(token && token.claims && (Object.keys(token.claims).includes("OPERATOR") || Object.keys(token.claims).includes("SUPER_ADMIN")) ? routesForOperatorOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;