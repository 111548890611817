import * as React from 'react';
import { styled } from '@mui/system';
import {
  TablePagination,
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { getReasonPhrase } from "http-status-codes";
import { useEffect, useState } from "react";
import { useAuth } from "../provider/authProvider";
import { Tooltip } from 'react-tooltip'
import NavBar from './NavBar';
import Footer from './Footer';

export default function UserBasedAudits({ ajaxHelper }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const defaultDate = () => {
        const now = new Date();
        const nowLocal = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
        return nowLocal.toISOString().slice(0, -1);
    }

    const { token } = useAuth();
    const [tableData, setTableData] = useState([]);
    const [minimumDate, setMinimumDate] = useState(defaultDate());
    const [maximumDate, setMaximumDate] = useState(defaultDate());

    const fetchData = async () => {
        try {
            const response = await ajaxHelper.get("/Audit", token.token, {type: "CREDENTIAL"});
            console.log(response.data);
            const data = response.data.sort((a, b) => new Date(b.when) - new Date(a.when));
            console.log(data);
            setTableData(data);
        } catch (err) {
            console.error(err);
        }
    }
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        const times = tableData.map(item => new Date(item.when));
        if (times.length === 0) return;
        console.log(times);
        const minDate = new Date(Math.min.apply(null, times));
        const maxDate = new Date(Math.max.apply(null, times));
        console.log(minDate, maxDate);
        // Format minDate and maxDate to be compatible with input type datetime-local, whilst using the toLocaleString method to convert the date to a string

        const minDateTzDiff = minDate.getTimezoneOffset();
        const parsedMinDate = minDate.getTime() - (minDateTzDiff * 60000);
        const formattedMinDate = new Date(parsedMinDate).toISOString().slice(0,-1);

        const maxDateTzDiff = maxDate.getTimezoneOffset();
        const parsedMaxDate = maxDate.getTime() - (maxDateTzDiff * 60000);
        const formattedMaxDate = new Date(parsedMaxDate).toISOString().slice(0,-1);

        console.log(formattedMinDate, formattedMaxDate)

        // const formattedMinDate = minDate.toISOString().slice(0,-1);
        // const formattedMaxDate = maxDate.toISOString().slice(0,-1);
        setMinimumDate(formattedMinDate);
        setMaximumDate(formattedMaxDate);

    }, [tableData])

    useEffect(() => {
        document.getElementById("from-time").value = minimumDate;
        document.getElementById("to-time").value = maximumDate;
    }, [minimumDate, maximumDate])

    function userIdFilter(item, search) {
        const email = item.request.find(field => field.name === "email")?.value;
        const userId = item.response.find(field => field.name === "userId")?.value;
        const name = item.response.find(field => field.name === "name")?.value;
        const who = item.who;
        return email?.toLowerCase().includes(search.toLowerCase()) || userId?.toLowerCase().includes(search.toLowerCase()) || name?.toLowerCase().includes(search.toLowerCase()) || who?.toLowerCase().includes(search.toLowerCase());
    }

    // Allow the user to filter the data by event ID
    const handleFilter = async (e) => {
        e.preventDefault();
        const userId = document.getElementById("user-id").value;
        const fromTime = new Date(document.getElementById("from-time").value);
        const toTime = new Date(document.getElementById("to-time").value);

        var predicate;

        if (!userId && fromTime && toTime) {
            predicate = item => new Date(item.when) >= fromTime && new Date(item.when) <= toTime;
        } else if (!userId && fromTime && !toTime) {
            predicate = item => new Date(item.when) >= fromTime;
        } else if (!userId && !fromTime && toTime) {
            predicate = item => new Date(item.when) <= toTime;
        } else if (userId && fromTime && toTime) {
            predicate = item => userIdFilter(item, userId) && new Date(item.when) >= fromTime && new Date(item.when) <= toTime;
        } else if (userId && fromTime && !toTime) {
            predicate = item => userIdFilter(item, userId) && new Date(item.when) >= fromTime;
        } else if (userId && !fromTime && toTime) {
            predicate = item => userIdFilter(item, userId) && new Date(item.when) <= toTime;
        } else if (userId && !fromTime && !toTime) {
            predicate = item => userIdFilter(item, userId);
        } else {
            setTableData(tableData);
            return;
        }

        tableData.forEach(item => console.log(new Date(item.when), fromTime, toTime));
            
        try {
            const data = tableData.filter(predicate);
            setTableData(data);
        } catch (err) {
            console.error(err);
        }
    }

    const handleClear = async (e) => {
        e.preventDefault();
        document.getElementById("user-id").value = "";
        setMinimumDate(defaultDate());
        setMaximumDate(defaultDate());
        fetchData();
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className='events-based-audits'>
            <NavBar />
            <main>
                <div className="filters">
                    <form onSubmit={handleFilter} className="filter-form">
                        <div className="field-container">
                            <p>
                                <label htmlFor="user-id">USER</label>
                                <input id="user-id" type="text" />
                            </p>
                        </div>
                        <div className="field-container">
                            <p>
                                <label htmlFor="from-time">FROM</label>
                                <input id="from-time" type="datetime-local" defaultValue={minimumDate} step="0.001" />
                            </p>
                        </div>
                        <div className="field-container">
                            <p>
                                <label htmlFor="to-time">TO</label>
                                <input id="to-time" type="datetime-local" defaultValue={maximumDate} step="0.001" />
                            </p>
                        </div>
                        <div className="centre-field-container">
                            <p>
                                <input type="submit" value="FILTER" />
                            </p>
                        </div>
                    </form>
                    <form onSubmit={handleClear}>
                        <div className="centre-field-container">
                            <p>
                                <input id="clear-filters" type="submit" value="CLEAR" />
                            </p>
                        </div>
                    </form>
                </div>
                <Root sx={{ width: '100%' }}>
                    <table aria-label="custom pagination table">
                        <thead>
                        <tr>
                            <th>STATUS CODE</th>
                            <th>TYPE</th>
                            <th>WHO</th>
                            <th>WHEN</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(rowsPerPage > 0
                            ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : tableData
                        ).map((item) => (
                            <tr key={item.id}>
                                <td>
                                <div id={`status${item.id}`} style={{color: item.statusCode > 0 ? (getReasonPhrase(item.statusCode) === "OK" ? "lightgreen" : "red") : "red"}}>
                                    {item.statusCode > 0 ? getReasonPhrase(item.statusCode) : item.statusCode}
                                    </div>
                                    <Tooltip clickable anchorSelect={`#status${item.id}`} style={{color: "white", backgroundColor: "black"}}>
                                        <div>
                                            <p><strong>CODE:</strong> {item.statusCode}</p>
                                            <p><strong>EXPLAINED:</strong> {item.statusCode > 0 ? getReasonPhrase(item.statusCode) : item.statusCode}</p>
                                        </div>
                                    </Tooltip>
                                </td>  
                                <td>
                                    <div id={`type${item.id}`}>
                                        {item.type.replace("CREDENTIAL/","")}
                                    </div>
                                    <Tooltip clickable anchorSelect={`#type${item.id}`} style={{color: "white", backgroundColor: "black"}}>
                                        <div>
                                            <p><strong>ID:</strong> {item.id}</p>
                                            <p><strong>TYPE:</strong> {item.type}</p>
                                            <p><strong>PATH:</strong> {item.path}</p>
                                        </div>
                                    </Tooltip>
                                </td>
                                <td>
                                    <div id={`who${item.id}`}>
                                        {item.request.find(field => field.name === "email")?.value ?? (item.response.find(field => field.name === "userId")?.value ?? item.who)}
                                    </div>
                                    <Tooltip clickable anchorSelect={`#who${item.id}`} style={{color: "white", backgroundColor: "black", height: "500px"}}>
                                        <div>
                                            <p><strong>EMAIL:</strong> {item.request.find(field => field.name === "email")?.value ?? "UKN"}</p>
                                            <p><strong>USER ID:</strong> {item.response.find(field => field.name === "userId")?.value ?? item.who}</p>
                                            <p><strong>NAME:</strong> {item.response.find(field => field.name === "name")?.value ?? "UKN"}</p>
                                        </div>
                                    </Tooltip>
                                </td>
                                <td>{new Date(item.when).toLocaleString()}</td>                      
                            </tr>
                        ))}
                        {emptyRows > 0 && (
                            <tr style={{ height: 41 * emptyRows }}>
                            <td colSpan={4} aria-hidden />
                            </tr>
                        )}
                        </tbody>
                        <tfoot>
                        <tr>
                            <CustomTablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={4}
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                                select: {
                                'aria-label': 'rows per page',
                                },
                                actions: {
                                showFirstButton: true,
                                showLastButton: true,
                                },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </tr>
                        </tfoot>
                    </table>
                </Root>
            </main>
            <div className='footer-padding' />
            <Footer />
        </div>
    );
    }

    const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
    };

    const Root = styled('div')(
    ({ theme }) => `
    table {
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: gray;
    }
    `,
    );

    const CustomTablePagination = styled(TablePagination)`
    & .${classes.toolbar} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        }
    }

    & .${classes.selectLabel} {
        margin: 0;
    }

    & .${classes.displayedRows} {
        margin: 0;

        @media (min-width: 768px) {
        margin-left: auto;
        }
    }

    & .${classes.spacer} {
        display: none;
    }

    & .${classes.actions} {
        display: flex;
        gap: 0.25rem;
    }
    `;
