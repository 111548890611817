import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { useEffect, useState } from "react";

export const ProtectedRoute = ({ ajaxHelper }) => {
    const { token, setToken } = useAuth();
    const [isValidating, setIsValidating] = useState(true);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            if (!token) {
                setIsValidating(false);
                return;
            }

            try {
                const response = await ajaxHelper.post("/Jwt/validate", { token: token.token }, '');

                if (response.status !== 200) {
                    localStorage.clear();
                    setToken(null);
                    sessionStorage.clear();
                    setIsValid(false);
                } else {
                    setIsValid(true);
                }
            } catch (error) {
                localStorage.clear();
                setToken(null);
                sessionStorage.clear();
                setIsValid(false);
            } finally {
                setIsValidating(false);
            }
        };

        validateToken();
    }, [token, ajaxHelper, setToken]);

    // Show loading state while validating
    if (isValidating) {
        return <div>Loading...</div>;
    }

    // If no token exists or token is invalid, redirect to login
    if (!token || !isValid) {
        return <Navigate to="/login" />;
    }

    // If authenticated and token is valid, render the child routes
    return <Outlet />;
};